import cx from "classnames"
import SearchIcon from "../../icons/SearchIcon"
import { useContext, useState } from "react"
import { SimilaritySearchContext } from "./SimilaritySearchContainer"
import { SPOTIFY_TRACK_LINK_REGEX, YOUTUBE_LINK_REGEX } from "../../shared/utils/helpers"

const SimilaritySearchInput = (props) => {
  const { searchCallback } = props
  const { setSearchQueryTerm, searchMode } = useContext(SimilaritySearchContext)
  const [searchQueryForm, setSearchQueryForm] = useState("")
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const searchModeOptions = [
    { label: "General Vibe", value: "mostRepresentative" },
    { label: "Whole Song", value: "complete" },
  ]

  const handleSearchChange = (e) => {
    setSearchQueryForm(e.target.value)
  }

  const handleMenuToggle = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    if (YOUTUBE_LINK_REGEX.test(searchQueryForm)) {
      setSearchQueryTerm(YOUTUBE_LINK_REGEX.exec(searchQueryForm)[0])
      searchCallback(YOUTUBE_LINK_REGEX.exec(searchQueryForm)[0])
    } else if (SPOTIFY_TRACK_LINK_REGEX.test(searchQueryForm)) {
      setSearchQueryTerm(searchQueryForm)
      searchCallback(searchQueryForm)
    } else {
      setSearchQueryTerm("")
    }
  }

  return (
    <form id="search" className="search mm-filter" onSubmit={handleSearchSubmit}>
      <div id="mm-search-input" className={cx("")}>
        <div className="search-input-group">
          <div className="icon">
            <SearchIcon />
          </div>
          <label>Search</label>
          <input
            autoFocus
            type="text"
            value={searchQueryForm}
            onChange={handleSearchChange}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            placeholder="Paste a YouTube or Spotify link to see similar songs"
            aria-label="Enter search text"
          />
        </div>
        <div className="search-filter" onClick={handleMenuToggle}>
          <span className="expand icon"></span>
          <div className="target">
            {searchMode?.label ?? "General Vibe"} <span></span>
          </div>
          <div className={cx("menu", isMenuVisible ? "visible" : "")}>
            {searchModeOptions.map((option) => (
              <SimilaritySearchModeListItem key={option.value} option={option} />
            ))}
          </div>
        </div>
      </div>
      <button className="youtube-submit" type="submit">
        Search
      </button>
    </form>
  )
}

const SimilaritySearchModeListItem = ({ option }) => {
  const { searchMode, setSearchMode } = useContext(SimilaritySearchContext)

  const handleSearchModeClick = (e) => {
    setSearchMode(option)
  }

  return (
    <div
      className={cx(
        "item",
        searchMode.value === option.value ||
          (!Object.keys(searchMode).length && option.value === "mostRepresentative")
          ? "selected"
          : ""
      )}
      data-value={option.value}
      data-cy={option.value}
      onClick={handleSearchModeClick}
    >
      {option.label}
    </div>
  )
}

export default SimilaritySearchInput
