import cx from "classnames"
import { SimilaritySearchContext } from "./SimilaritySearchContainer"
import { useContext } from "react"
import FilterInstrumentalIcon from "../../icons/FilterInstrumentalIcon"
import FilterVocalsIcon from "../../icons/FilterVocalsIcon"
import FilterVocalsFemaleIcon from "../../icons/FilterVocalsFemaleIcon"
import FilterVocalsMaleIcon from "../../icons/FilterVocalsMaleIcon"

const SimilaritySearchFilters = () => {
  //false here is a placeholder for when we plan to change how we display filters
  return (
    <article
      className={cx("similarity-filters-container", false ? "closed" : "")}
      aria-label="Similarity Search Filters"
    >
      <form class="filters">
        <SimilarityVoiceFilter />
      </form>
    </article>
  )
}

const SimilarityVoiceFilter = () => {
  const { filters, setFilters } = useContext(SimilaritySearchContext)

  const handleOptionClick = (option) => {
    if (filters.voice === option) {
      setFilters((curr) => ({ ...curr, voice: "" }))
    } else {
      setFilters((curr) => ({ ...curr, voice: option }))
    }
  }

  return (
    <fieldset id={"voice"} className={cx("mm-filter current")}>
      <ul>
        <li
          className={cx("actionable", "false", filters.voice === "instrumental" ? "selected" : "")}
          data-val="false"
          data-pretty="Instrumental"
          data-cy="vocals-option-Instrumental"
        >
          <a
            data-prevent-default
            className="inner"
            onClick={() => handleOptionClick("instrumental")}
          >
            <figure>
              <span className="icon">
                <FilterInstrumentalIcon />
              </span>
              <figcaption className="desktop">Instrumental</figcaption>
              <figcaption className="mobile">None</figcaption>
            </figure>
          </a>
        </li>

        <li
          className={cx("actionable", "true", filters.voice === "vocals" ? "selected" : "")}
          data-val="true"
          data-pretty="Vocals"
          data-cy="vocals-option-Vocals"
        >
          <a data-prevent-default className="inner" onClick={() => handleOptionClick("vocals")}>
            <figure>
              <span className="icon">
                <FilterVocalsIcon />
              </span>
              <figcaption className="desktop">All Vocals</figcaption>
              <figcaption className="mobile">Vocals</figcaption>
            </figure>
          </a>
        </li>

        <li
          className={cx("actionable", "female", filters.voice === "female" ? "selected" : "")}
          data-val="female"
          data-pretty="Female"
          data-cy="vocals-option-Female"
        >
          <a data-prevent-default className="inner" onClick={() => handleOptionClick("female")}>
            <figure>
              <span className="icon">
                <FilterVocalsFemaleIcon />
              </span>
              <figcaption>Female</figcaption>
            </figure>
          </a>
        </li>

        <li
          className={cx("actionable", "male", filters.voice === "male" ? "selected" : "")}
          data-val="male"
          data-pretty="Male"
          data-cy="vocals-option-Male"
        >
          <a data-prevent-default className="inner" onClick={() => handleOptionClick("male")}>
            <figure>
              <span className="icon">
                <FilterVocalsMaleIcon />
              </span>
              <figcaption>Male</figcaption>
            </figure>
          </a>
        </li>
      </ul>
    </fieldset>
  )
}

export default SimilaritySearchFilters
