import React, { useEffect, useRef, useMemo } from "react"
import { useContext, useState } from "react"
import MultiStepForm from "../../shared/components/multi_step_form/MultiStepForm"
import FormContext from "../../shared/components/multi_step_form/MultiStepFormContext"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as yup from "yup"
import TextField from "../../shared/components/TextField"
import { DragDrop, StatusBar } from "@uppy/react"
import AwsS3 from "@uppy/aws-s3"
import Uppy from "@uppy/core"
import axios from "axios"
import cx from "classnames"
import AsyncSelectField from "../../shared/components/AsyncSelectField"
import debounce from "debounce-promise"
import CreatableSelectField from "../../shared/components/CreatableSelectField"
import Label from "../../shared/components/Label"
import formStyles from "../../shared/styles/form.module.scss"
import {
  communityOptions,
  ethnicityOptions,
  genderOptions,
  pronounOptions,
} from "../../shared/utils/helpers"
import { countryOptions, regionOptions } from "../../shared/data/selectOptions"
import SelectField from "../../shared/components/SelectField"
import { showErrorBorder } from "../../shared/utils/form"
import SmileyFaceIcon from "../../icons/SmileyFaceIcon"

const NewArtistFormContainer = () => {
  const el = document.getElementById("mm-new-artist-form-container")
  const userRole = el && el.getAttribute("data-user-role")
  const defaultVendors = el && JSON.parse(el.getAttribute("data-vendor-options"))
  const presignedPostUrl = "/s3_presigned_post"
  const containerRef = useRef(null)
  const [currentStep, setCurrentStep] = useState(0)
  const imageIdMap = {}
  const [formState, setFormState] = useState({
    vendor: "", //{ label: "", value: "" },
    name: "",
    bio: "",
    pronouns: [],
    communities: [],
    gender: [],
    ethnicity: [],
    current_locale: { country: "", state: "", city: "" },
    place_of_origin: { country: "", state: "", city: "" },
    date_of_birth: "",
    union: "",
    union_sessions: "",
    merch: "",
    vinyl: "",
    links: {
      vimeo: "",
      itunes: "",
      tiktok: "",
      spotify: "",
      twitter: "",
      website: "",
      youtube: "",
      bandcamp: "",
      facebook: "",
      instagram: "",
      soundcloud: "",
    },
    photos: [],
  })

  const imageUppy = useMemo(() => {
    return Uppy({
      autoProceed: true,
      allowMultipleUploads: true,
      restrictions: {
        maxNumberOfFiles: 3,
        allowedFileTypes: ["image/jpg", "image/jpeg", "image/png"],
      },
    }).use(AwsS3, {
      async getUploadParameters(file) {
        const { data } = await axios.get(presignedPostUrl, {
          params: { bucket: "uploads/artist" },
        })
        imageIdMap[file.data.name] = file.id
        const { url, fields } = data
        return { method: "post", url, fields }
      },
    })
  }, [])

  useEffect(() => {
    return () => imageUppy.close()
  }, [])

  useEffect(() => {
    containerRef.current.scrollIntoView({ behavior: "smooth" })
  }, [currentStep])

  return (
    <div className="new-artist-form-container" ref={containerRef}>
      <div className="left-section"></div>
      <div className="form-box">
        <MultiStepForm
          formState={{ formState, setFormState, userRole, defaultVendors }}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        >
          <VendorSelection key="Vendor" />
          <ArtistNameBioInput key="Name & Bio" />
          <PhotoUploads imageUppy={imageUppy} key="Images" />
          <IdentitySurvey key="Identities" />
          <SocialLinksInput key="Social Media" />
          <FinalQuestions key="Final Questions" />
          <ArtistReview key="Review" />
        </MultiStepForm>
      </div>
      <div className="right-section">
        {currentStep === 3 && (
          <div className="hint-box">
            <p className="extra-hint">
              Marmoset is committed to equity as the foundation of everything we do, and
              self-identification by our creative partners plays a critical role in this effort.
              This enables us to provide transparency to our staff and clients, as well as celebrate
              our community during important cultural moments.
            </p>
            <p className="extra-hint">
              *This info isn’t just for our records: Increasingly clients are requesting music by
              artists that are local to a specific area, or artists with a specific identity for a
              project that centers members of that community.
            </p>
            <p className="extra-hint">
              When you provide self-identification, it provides us with another tool we can use to
              pitch your music for projects. If you don’t provide this information, we do not make
              assumptions on your behalf. If we don’t have the info, we won’t be able to include you
              in relevant opportunities.
            </p>
            <p className="extra-hint">
              Of course, you are welcome to opt out of any/all questions - simply type “Prefer not
              to say.” You can also let us know any information you'd like us to keep private.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

const VendorSelection = () => {
  const { formState, setFormState, next, userRole, defaultVendors } = useContext(FormContext)
  const validationSchema = yup.object({
    vendor: yup.object().required("Vendor is required"),
  })

  const searchContributors = async (query) => {
    return await axios
      .get("/api/v1/vendors/artist_form_autocomplete", { params: { query } })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error)
        return []
      })
  }

  return (
    <Formik
      initialValues={{ vendor: formState.vendor }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setFormState({ ...formState, ...values })
          setSubmitting(false)
          next()
        }, 400)
      }}
    >
      {({ isSubmitting, setFieldTouched, setFieldValue, values }) => (
        <Form>
          <AsyncSelectField
            admin={false}
            label="Vendor"
            fieldName={`vendor`}
            value={values.vendor}
            loadOptions={debounce(searchContributors, 400)}
            defaultOptions={userRole == "vendor" && defaultVendors}
            placeholder="Vendor..."
            canCreateOption={false}
            onBlur={setFieldTouched}
            onChange={setFieldValue}
          />
          <div className="button-container">
            <button style={{ opacity: "0%" }} disabled={true}></button>
            <button type="submit" disabled={isSubmitting}>
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const ArtistNameBioInput = () => {
  const { formState, setFormState, next, prev } = useContext(FormContext)
  const inputRef = useRef()
  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Name is required")
      .min(1)
      .max(100)
      .matches(/^[^.]*$/, {
        message: "No period",
      })
      .matches(/^[^!@#$%^&*+=<>:;|~]*$/, {
        message: "No symbols",
      }),
    bio: yup.string().required("Biography is required").min(10).max(100),
  })

  const isNameTaken = async (name) => {
    if (!name) return true
    const resp = await axios.get("/api/v1/artists/autocomplete", { params: { query: name } })
    const artists = await resp.data
    const sameName = artists.filter((artist) => artist.label.toLowerCase() === name.toLowerCase())
    return sameName.length
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <Formik
      initialValues={{ name: formState.name, bio: formState.bio }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const nameTaken = await isNameTaken(values.name)
        if (!!nameTaken) {
          setErrors({ name: "Name is already taken, Please Try another." })
          setSubmitting(false)
          return
        }
        setTimeout(() => {
          setFormState({ ...formState, ...values })
          setSubmitting(false)
          next()
        }, 400)
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <TextField type="text" fieldName="name" label="Name" innerRef={inputRef} />
          <p className="extra-hint">
            What is the moniker of this artist/band/project? Avoid using special characters if
            possible, this improves searchability for our clients.
          </p>
          <div className="bio-label-field">
            <Label text="Bio" htmlFor="bio" classNames={cx(formStyles.label)} />
            <Field
              component="textarea"
              rows={5}
              cols={100}
              maxLength={500}
              name="bio"
              placeholder="Tell us a little something about yourself"
              id="artist-bio-input"
            />
          </div>
          <ErrorMessage name="bio" className="error-msg" component="div" />
          <p className="extra-hint">
            2-5 sentences, including information about where you're from and what your music sounds
            like. We recommend including 1-2 sentences about yourself, 1-2 sentences describing your
            music, and a nod to your location. Share 2-3 “for fans of” artists with a similar sound.
            Please use a minimum of 50 words and a maximum of 100 words. This bio needs to be unique
            and distinct from any other artist profiles you have on Marmoset.
          </p>
          <p className="extra-hint">This bio is a great example of what we’re looking for:</p>
          <p className="extra-hint">
            "Moia Bri is an ascending force in the Seattle music scene. Filipino-born, her voice
            cuts through the noise with a precise combination of power and emotion. Embracing her
            fun crossover style, Moia sprinkles a dash of her Pop and R&B influence into every
            record. Fans of Kehlani and Ariana Grande will find themselves easily vibing to Bri."
          </p>
          <div className="button-container">
            <button type="button" className="previous-step" onClick={() => prev()}>
              Go Back
            </button>
            <button type="submit" disabled={isSubmitting}>
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const PhotoUploads = ({ imageUppy }) => {
  const { formState, setFormState, next, prev } = useContext(FormContext)

  const deleteFromUppy = (photoUrl) => {
    const uppyFiles = Object.values(imageUppy?.state.files)
      .filter((file) => file.uploadURL == photoUrl)
      .map((file) => file.id)
    imageUppy.removeFiles(uppyFiles, null)
  }

  const handleRemovePhoto = (idx) => {
    let newPhotoArray = formState.photos
    const location = newPhotoArray.splice(idx, 1)
    setFormState({ ...formState, photos: newPhotoArray })
    deleteFromUppy(location[0])
  }

  useEffect(() => {
    const handler = (status, event) => {
      setFormState((curr) => ({ ...curr, photos: [...curr.photos, event.body.location] }))
    }
    imageUppy.on("upload-success", handler)
    // Tell React to remove the old listener if a different function is passed to the `handleFileUploaded` prop:
    return () => {
      imageUppy.off("upload-success", handler)
    }
  }, [])

  return (
    <Formik>
      <Form>
        <fieldset>
          <legend>File Upload</legend>
          <DragDrop
            uppy={imageUppy}
            pretty={true}
            height="160px"
            inputName="photos"
            note="Image files only (.jpg/jpeg, .png)"
            locale={{
              strings: { dropHereOr: "Drop file here or %{browse}" },
            }}
          />
          <StatusBar
            uppy={imageUppy}
            hideAfterFinish={false}
            hideCancelButton={false}
            showProgressDetails
          />
          <p className="extra-hint">
            Submit at least 3 images - one landscape (16:9), one portrait (9:16), and one square
            format. Ensure your image files are a minimum of 1280 pixels wide x 960 pixels high. A
            person (or people) should be centered in each photo.
          </p>
          <p className="extra-hint">
            It’s O K to get creative, but please: high-quality photography only. No out-of-focus or
            otherwise low quality images; no vacation snapshots. iPhone photos are O K as long as
            they were taken on the highest quality settings.
          </p>
        </fieldset>
        <div className="photo-options">
          {formState.photos &&
            formState.photos.map((photo, idx) => (
              <div
                className="photo-option-container"
                style={{ backgroundImage: `url(${photo})` }}
                key={idx}
              >
                <button
                  type="button"
                  className="remove-photo-option"
                  onClick={() => handleRemovePhoto(idx)}
                >
                  x
                </button>
              </div>
            ))}
        </div>
        <div className="button-container">
          <button type="button" className="previous-step" onClick={() => prev()}>
            Go Back
          </button>
          <button
            className="form-step-button"
            type="button"
            disabled={formState.photos.length < 3}
            onClick={next}
          >
            Next
          </button>
        </div>
      </Form>
    </Formik>
  )
}

const IdentitySurvey = () => {
  const { formState, setFormState, next, prev } = useContext(FormContext)
  const [regions, setRegions] = useState([])
  const [originRegions, setOriginRegions] = useState([])

  const validationSchema = yup.object({
    pronouns: yup.object().nullable(),
    communities: yup.object().nullable(),
    gender: yup.object().nullable(),
    ethnicity: yup.object().nullable(),
    current_locale: yup
      .object({
        country: yup
          .object({ label: yup.string().nullable(), value: yup.string().nullable() })
          .nullable(),
        state: yup
          .object({ label: yup.string().nullable(), value: yup.string().nullable() })
          .nullable(),
        city: yup.string().nullable(),
      })
      .nullable(),
    place_of_origin: yup
      .object({
        country: yup
          .object({ label: yup.string().nullable(), value: yup.string().nullable() })
          .nullable(),
        state: yup
          .object({ label: yup.string().nullable(), value: yup.string().nullable() })
          .nullable(),
        city: yup.string().nullable(),
      })
      .nullable(),
    date_of_birth: yup.date("Must be valid date").nullable(),
  })

  const handleSelectedCountry = (fieldName, selectedValue, setFieldValue, values) => {
    const selectedCountry = selectedValue.value
    const prevCountry = values.current_locale.country.value
    setFieldValue(fieldName, selectedValue)

    if (selectedCountry !== "" && selectedCountry !== prevCountry) {
      setRegions(regionOptions(selectedCountry))
      setFieldValue("current_locale.state", {
        label: "Select a region...",
        value: "",
      })
      setFieldValue("place_of_origin.city", "")
    }
  }

  const handleSelectedOriginCountry = (fieldName, selectedValue, setFieldValue, values) => {
    const selectedCountry = selectedValue.value
    const prevCountry = values.place_of_origin.country.value
    setFieldValue(fieldName, selectedValue)

    if (selectedCountry !== "" && selectedCountry !== prevCountry) {
      setOriginRegions(regionOptions(selectedCountry))
      setFieldValue("place_of_origin.state", {
        label: "Select a region...",
        value: "",
      })
      setFieldValue("place_of_origin.city", "")
    }
  }

  return (
    <Formik
      initialValues={{
        pronouns: formState.pronouns,
        communities: formState.communities,
        gender: formState.gender,
        ethnicity: formState.ethnicity,
        current_locale: formState.current_locale,
        place_of_origin: formState.place_of_origin,
        date_of_birth: formState.date_of_birth,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setFormState({ ...formState, ...values })
          setSubmitting(false)
          next()
        }, 400)
      }}
    >
      {({ isSubmitting, setFieldValue, values, setFieldTouched, errors, touched }) => (
        <Form>
          <section className="identities-section">
            {/* <TextField type="text" fieldName="pronouns" label="Pronouns" list="pronouns" /> */}
            <CreatableSelectField
              isMulti={true}
              label="Prounouns"
              fieldName="pronouns"
              placeholder="Select or type your pronouns..."
              value={values.pronouns}
              isClearable={true}
              options={pronounOptions}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
            {/* <TextField type="text" fieldName="communities" label="Communities" /> */}
            <CreatableSelectField
              isMulti={true}
              label="Communities"
              fieldName="communities"
              placeholder="Select or type your community..."
              value={values.communities}
              isClearable={true}
              options={communityOptions}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
            {/* <TextField type="text" fieldName="gender" label="Gender" /> */}
            <CreatableSelectField
              isMulti={true}
              label="Gender"
              fieldName="gender"
              placeholder="Select or type your gender..."
              hint="What best describes the gender expression of the member(s) of this artist?"
              value={values.gender}
              isClearable={true}
              options={genderOptions}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
            {/* <TextField type="text" fieldName="ethnicity" label="Ethnicity" /> */}
            <CreatableSelectField
              isMulti={true}
              label="Ethnicity"
              fieldName="ethnicity"
              placeholder="Select or type your ethnicity..."
              hint="What best describes the ethnicity/race of the member(s) of this artist?"
              value={values.ethnicity}
              isClearable={true}
              options={ethnicityOptions}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
            {/* <TextField type="text" fieldName="current_locale" label="Current Locale" /> */}
            <Label
              htmlFor={"current_locale"}
              text="Current Locale"
              hint="What city/state/country does the artist currently live in? "
              classNames={cx(formStyles.label)}
            />
            <SelectField
              label="Current Country"
              fieldName="current_locale.country"
              options={countryOptions}
              value={values.current_locale.country}
              onChange={(field, value) =>
                handleSelectedCountry(field, value, setFieldValue, values)
              }
              onBlur={setFieldTouched}
              showErrorBorder={showErrorBorder(errors, touched, "current_locale.country")}
            />
            {(regions.length > 0 || values.current_locale.country) && (
              <SelectField
                label="Current State"
                fieldName="current_locale.state"
                options={regions}
                value={values.current_locale.state}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                showErrorBorder={showErrorBorder(errors, touched, "current_locale.state")}
              />
            )}
            {!!values.current_locale.state.value && (
              <TextField type="text" fieldName="current_locale.city" label="Current City" />
            )}
            <Label
              htmlFor={"place_of_origin"}
              text={"Place of Origin"}
              hint={
                "If it's important to your story, we'd love to know where this artist grew up, if different than where they live now."
              }
              classNames={cx(formStyles.label)}
            />
            <SelectField
              label="Origin Country"
              fieldName="place_of_origin.country"
              options={countryOptions}
              value={values.place_of_origin.country}
              onChange={(field, value) =>
                handleSelectedOriginCountry(field, value, setFieldValue, values)
              }
              onBlur={setFieldTouched}
              showErrorBorder={showErrorBorder(errors, touched, "place_of_origin.country")}
            />
            {(originRegions.length > 0 || values.place_of_origin.country) && (
              <SelectField
                label="Origin State"
                fieldName="place_of_origin.state"
                options={originRegions}
                value={values.place_of_origin.state}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                showErrorBorder={showErrorBorder(errors, touched, "place_of_origin.state")}
              />
            )}
            {!!values.place_of_origin.state.value && (
              <TextField type="text" fieldName="place_of_origin.city" label="Origin City" />
            )}
            <TextField type="date" fieldName="date_of_birth" label="Date of Birth" />
          </section>
          <div className="button-container">
            <button type="button" className="previous-step" onClick={() => prev()}>
              Go Back
            </button>
            <button type="submit" disabled={isSubmitting}>
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const SocialLinksInput = () => {
  const { formState, setFormState, next, prev } = useContext(FormContext)

  const validationSchema = yup.object({
    links: yup.object().shape({
      vimeo: yup.string().url("Must be valid URL").nullable(),
      itunes: yup.string().url("Must be valid URL").nullable(),
      tiktok: yup.string().url("Must be valid URL").nullable(),
      spotify: yup.string().url("Must be valid URL").nullable(),
      twitter: yup.string().url("Must be valid URL").nullable(),
      website: yup.string().url("Must be valid URL").nullable(),
      youtube: yup.string().url("Must be valid URL").nullable(),
      bandcamp: yup.string().url("Must be valid URL").nullable(),
      facebook: yup.string().url("Must be valid URL").nullable(),
      instagram: yup.string().url("Must be valid URL").nullable(),
      soundcloud: yup.string().url("Must be valid URL").nullable(),
    }),
  })

  return (
    <Formik
      initialValues={{ links: formState.links }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setFormState({ ...formState, ...values })
          setSubmitting(false)
          next()
        }, 400)
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <section className="links-section">
            <p className="extra-hint centered">
              Please include your top 5 preferred social links for folks to connect with you from
              the list below.
            </p>
            <TextField
              type="text"
              fieldName="links.vimeo"
              label="Vimeo"
              placeholder="E.g., https://www.vimeo.com/trackclub"
            />
            <TextField
              type="text"
              fieldName="links.itunes"
              label="Apple Music"
              placeholder="E.g., https://music.apple.com/lt/artist/bill-wurtz/1019208137"
            />
            <TextField
              type="text"
              fieldName="links.tiktok"
              label="Tiktok"
              placeholder="E.g., https://www.tiktok.com/@wearetrackclub"
            />
            <TextField
              type="text"
              fieldName="links.spotify"
              label="Spotify"
              placeholder="E.g., https://open.spotify.com/artist/43sZBwHjahUvgbx1WNIkIz"
            />
            <TextField
              type="text"
              fieldName="links.twitter"
              label="Twitter"
              placeholder="E.g., https://x.com/wearetrackclub"
            />
            <TextField
              type="text"
              fieldName="links.website"
              label="Website"
              placeholder="E.g., https://www.trackclub.com"
            />
            <TextField
              type="text"
              fieldName="links.youtube"
              label="Youtube"
              placeholder="E.g., https://www.youtube.com/@wearetrackclub or https://www.youtube.com/channel/UCQFqnsLgB_VHSwFYHVqaGOg"
            />
            <TextField
              type="text"
              fieldName="links.bandcamp"
              label="Bandcamp"
              placeholder="E.g., https://trackclub.bandcamp.com"
            />
            <TextField
              type="text"
              fieldName="links.facebook"
              label="Facebook"
              placeholder="E.g., https://www.facebook.com/wearetrackclub"
            />
            <TextField
              type="text"
              fieldName="links.instagram"
              label="Instagram"
              placeholder="E.g., https://www.instagram.com/wearetrackclub"
            />
            <TextField
              type="text"
              fieldName="links.soundcloud"
              label="Soundcloud"
              placeholder="E.g., https://soundcloud.com/trackclub"
            />
          </section>
          <div className="button-container">
            <button type="button" className="previous-step" onClick={() => prev()}>
              Go Back
            </button>
            <button type="submit" disabled={isSubmitting}>
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const FinalQuestions = () => {
  const { formState, setFormState, prev, next } = useContext(FormContext)
  const validationSchema = yup.object({
    union: yup.string().required("This field is required"),
    union_sessions: yup.boolean().required("This field is required"),
    merch: yup.boolean().required("This field is required"),
    vinyl: yup.boolean().required("This field is required"),
  })

  return (
    <Formik
      initialValues={{
        union: formState.union,
        union_sessions: formState.union_sessions,
        merch: formState.merch,
        vinyl: formState.vinyl,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setFormState({ ...formState, ...values })
          setSubmitting(false)
          next()
        }, 400)
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <section className="final-questions-section">
            <Label
              htmlFor={"union"}
              text={"Are you a member of any of the unions listed below? *"}
              classNames={cx(formStyles.label)}
            />
            <p className="extra-hint">
              Answering yes or no will not affect your standing with Marmoset, however it is
              information that is sometimes needed to report to our licensees.
            </p>
            <div className="radio-group" role="group" aria-labelledby="my-radio-group">
              <label>
                <Field type="radio" name="union" value="AFM" />
                <span className="radio-label" style={{ backgroundColor: "#d1e2ff" }}>
                  AFM
                </span>
              </label>
              <label>
                <Field type="radio" name="union" value="SAG" />
                <span className="radio-label" style={{ backgroundColor: "#c4ecff" }}>
                  SAG
                </span>
              </label>
              <label>
                <Field type="radio" name="union" value="Other" />
                <span className="radio-label" style={{ backgroundColor: "#c1f5f0" }}>
                  Other
                </span>
              </label>
              <label>
                <Field type="radio" name="union" value="None" />
                <span className="radio-label" style={{ backgroundColor: "#cff5d1" }}>
                  None
                </span>
              </label>
              <ErrorMessage name="union" className="error-msg" component="div" />
            </div>
            {/* Union Sessions? */}
            <Label
              htmlFor={"sessions"}
              text={"Union Sessions? *"}
              classNames={cx(formStyles.label)}
            />
            <p className="extra-hint">
              Are any of your songs we represent recorded as "union sessions"?
            </p>
            <div className="radio-group" role="group" aria-labelledby="my-radio-group">
              <label>
                <Field type="radio" name="union_sessions" value="true" />
                <span className="radio-label" style={{ backgroundColor: "#cff5d1" }}>
                  Yes
                </span>
              </label>
              <label>
                <Field type="radio" name="union_sessions" value="false" />
                <span className="radio-label" style={{ backgroundColor: "#ffd4e0" }}>
                  No
                </span>
              </label>
              <ErrorMessage name="union_sessions" className="error-msg" component="div" />
            </div>
            {/* Merch? */}
            <Label
              htmlFor={"merch"}
              text={"Got vinyl or merch?  *"}
              classNames={cx(formStyles.label)}
            />
            <p className="extra-hint">
              We sometimes get the chance to buy vinyl or merch from artists we represent to send
              out to some of our top-tier clients. Knowing if you have vinyl or merch available is
              super helpful for us so we can make sure we consider you for these opportunities.
            </p>
            <Label text="Vinyl? *" />
            <div className="radio-group" role="group" aria-labelledby="my-radio-group">
              <label>
                <Field type="radio" name="vinyl" value="true" />
                <span className="radio-label" style={{ backgroundColor: "#cff5d1" }}>
                  Yes! I have vinyl available.
                </span>
              </label>
              <label>
                <Field type="radio" name="vinyl" value="false" />
                <span className="radio-label" style={{ backgroundColor: "#ffd4e0" }}>
                  No, I'm purely digital.
                </span>
              </label>
              <ErrorMessage name="vinyl" className="error-msg" component="div" />
            </div>
            <Label text="Merch? *" />
            <div className="radio-group" role="group" aria-labelledby="my-radio-group">
              <label>
                <Field type="radio" name="merch" value="true" />
                <span className="radio-label" style={{ backgroundColor: "#cff5d1" }}>
                  Yes! I have merch available.
                </span>
              </label>

              <label>
                <Field type="radio" name="merch" value="false" />
                <span className="radio-label" style={{ backgroundColor: "#ffd4e0" }}>
                  Nope, no merch
                </span>
              </label>
              <ErrorMessage name="merch" className="error-msg" component="div" />
            </div>
          </section>
          <div className="button-container">
            <button type="button" className="previous-step" onClick={() => prev()}>
              Go Back
            </button>
            <button type="submit" disabled={isSubmitting}>
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const ArtistReview = () => {
  const { formState, prev } = useContext(FormContext)
  const {
    vendor,
    name,
    bio,
    links,
    photos,
    pronouns,
    communities,
    gender,
    ethnicity,
    current_locale,
    place_of_origin,
    date_of_birth,
    union,
    union_sessions,
    vinyl,
    merch,
  } = formState

  //Can probably combine these 4 into one state var
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [message, setMessage] = useState("")
  const [portalLink, setPortalLink] = useState("")
  const [isSuccessful, setIsSuccessful] = useState(false)
  const linksArray = Object.entries(links).filter((entry) => entry[1] !== "")

  const locationString = (localeObject) => {
    const { country, state, city } = localeObject
    if (!country.value) return ""

    const stateString = !!state.value ? `, ${state.value}` : ""
    const cityString = !!stateString && city ? `, ${city}` : ""

    return country.value + stateString + cityString
  }

  const body = {
    vendor: vendor.value,
    bio,
    name,
    link: links,
    photo_option_1: photos[0] ?? "",
    photo_option_2: photos[1] ?? "",
    photo_option_3: photos[2] ?? "",
    pronouns: pronouns.length ? pronouns.map((obj) => obj.value).join(", ") : "",
    communities: communities.length ? communities.map((obj) => obj.value).join(", ") : "",
    gender: gender.length ? gender.map((obj) => obj.value).join(", ") : "",
    ethnicity: ethnicity.length ? ethnicity.map((obj) => obj.value).join(", ") : "",
    current_locale: locationString(current_locale),
    place_of_origin: locationString(place_of_origin),
    date_of_birth,
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    setMessage("")
    axios
      .post("/api/v1/artists/create", { ...body })
      .then((resp) => {
        const result = resp.data
        if (!result.success) {
          console.log("Failed to create new artist: ", result.message)
          setMessage(result.message)
        } else {
          setIsSuccessful(true)
          setPortalLink(result.portal_link)
        }
      })
      .catch((e) => {
        setMessage(e.message)
        console.log(e)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return isSuccessful ? (
    <>
      <div className="submission-success">
        <SmileyFaceIcon />
      </div>
      <span className="success-message">{message === "" ? "Success!" : message}</span>
      <a className="success-portal-link" href={portalLink ?? "/manage/artists"}>
        Click here to view in portal
      </a>
    </>
  ) : (
    <form>
      <section className="review-page">
        <Label text="Details" htmlFor="details" />
        <p className="vendor-detail">Vendor: {formState.vendor.label}</p>
        <p className="vendor-detail">Name: {formState.name}</p>
        <p className="vendor-detail">Bio: {formState.bio}</p>
        <Label text="Identities" />
        <p className="vendor-detail">
          Pronouns: {pronouns.length ? pronouns.map((obj) => obj.value).join(", ") : "Not Provided"}
        </p>
        <p className="vendor-detail">
          Communities:{" "}
          {communities.length ? communities.map((obj) => obj.value).join(", ") : "Not Provided"}
        </p>
        <p className="vendor-detail">
          Gender: {gender.length ? gender.map((obj) => obj.value).join(", ") : "Not Provided"}
        </p>
        <p className="vendor-detail">
          Ethnicity:{" "}
          {ethnicity.length ? ethnicity.map((obj) => obj.value).join(", ") : "Not Provided"}
        </p>
        <p className="vendor-detail">
          Place of Origin: {locationString(place_of_origin) ?? "Not Provided"}
        </p>
        <p className="vendor-detail">
          Current Locale: {locationString(current_locale) ?? "Not Provided"}
        </p>
        <p className="vendor-detail">Date of Birth: {date_of_birth ?? "Not Provided"}</p>
        <Label text="Additional Info" />
        <p className="vendor-detail">Union: {union ?? "Not Provided"}</p>
        <p className="vendor-detail">
          Union Sessions:{" "}
          {union_sessions === "true" ? (
            <span className="pill-label" style={{ backgroundColor: "#cff5d1" }}>
              Yes!
            </span>
          ) : (
            <span className="pill-label" style={{ backgroundColor: "#ffd4e0" }}>
              No
            </span>
          )}
        </p>
        <p className="vendor-detail">
          Vinyl:{" "}
          {vinyl === "true" ? (
            <span className="pill-label" style={{ backgroundColor: "#cff5d1" }}>
              Yes!
            </span>
          ) : (
            <span className="pill-label" style={{ backgroundColor: "#ffd4e0" }}>
              No
            </span>
          )}
        </p>
        <p className="vendor-detail">
          Merch:{" "}
          {merch === "true" ? (
            <span className="pill-label" style={{ backgroundColor: "#cff5d1" }}>
              Yes!
            </span>
          ) : (
            <span className="pill-label" style={{ backgroundColor: "#ffd4e0" }}>
              No
            </span>
          )}
        </p>
        <div className="photo-options">
          {formState.photos.map((photo, idx) => (
            <div
              className="photo-option-container"
              style={{ backgroundImage: `url(${photo})` }}
              key={idx}
            ></div>
          ))}
        </div>
        <Label text="Links" htmlFor="links" />
        <ul className="links-list">
          {linksArray.map((link) => {
            return (
              <li key={link[0]} className="artist-link">
                {link[0]}: {link[1]}
              </li>
            )
          })}
        </ul>
        <div className="button-container">
          <button type="button" className="previous-step" onClick={() => prev()}>
            Go Back
          </button>
          {message && !isSuccessful && <span className="error-msg">{message}</span>}
          <button
            type="button"
            className="form-step-button"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Processing" : "Submit"}
          </button>
        </div>
      </section>
    </form>
  )
}

export default NewArtistFormContainer
