import { gql } from "@apollo/client"

const internal_catalog_colors = {
  for_marmoset: "#dac8ef",
  artist_collab: "#ffd39d",
  pre_release: "#7ae1dd",
  fervor: "#b2cee0",
  not_for_tv_film: "#edaaa1",
  in_track_club: "#157893", //track-ocean-blue
}

const should_display_color = ["international_partner", "admin", "internal"]

export const getSongColor = (artist, user_role, forMarmoset) => {
  if (
    artist &&
    artist.catalog &&
    artist.catalog.hexColor &&
    artist.catalog.hexColor != "" &&
    should_display_color.indexOf(user_role) > -1
  ) {
    return artist.catalog.hexColor
  } else if (["internal", "admin"].includes(user_role) && forMarmoset) {
    return internal_catalog_colors.for_marmoset
  } else {
    return false
  }
}

export const getSongVersionColor = (songColor, user_role, sv) => {
  if (songColor) {
    return songColor
  } else if (should_display_color.includes(user_role)) {
    if (sv.preRelease || sv.is_pre_release) {
      return internal_catalog_colors.pre_release
    } else if (sv.notForTvFilm || sv.is_not_for_tv_film) {
      return internal_catalog_colors.not_for_tv_film
    } else if (sv.artistCollab || sv.is_artist_collab) {
      return internal_catalog_colors.artist_collab
    } else if (sv.fervor || sv.is_fervor) {
      return internal_catalog_colors.fervor
    } else {
      return false
    }
  }
}

export const sanitizedTitle = (title) => {
  return title
    .toLowerCase()
    .replace(/\s/g, "-")
    .replace(/[^a-z0-9-]/g, "")
}

export const setExclusivityMessage = (vintage, exclusive) => {
  if (vintage && exclusive) {
    return (
      <p>
        This song has some restrictions and vintage songs are priced at a higher rate, contact us
        for a custom license.
      </p>
    )
  } else if (vintage) {
    return <p>Vintage songs are priced at a higher rate, contact us for a custom license.</p>
  } else if (exclusive) {
    return <p>This song has some restrictions, contact us for conditions and terms.</p>
  }
}

export const sortSongVersions = (songVersions, arr) => {
  var sorted = songVersions
    .filter((sv) => {
      return arr.indexOf(sv.id) > -1
    })
    .sort((a, b) => (a.position > b.position ? 1 : -1))

  return sorted
}

export const GET_SONG_SONG_VERSIONS = gql`
  query GetSongSongVersions($id: ID!) {
    songById(id: $id) {
      name
      hasPastLicenses
      exclusive
      forMarmoset
      songVersionsCount
      rank
      songVersions {
        id
        title
        displayName
        playerArt
        songVersionFile
        arc
        length
        fervor
        notForTvFilmCatalog
        vintage
        lyrics
        customizable
        joinedGenreList
        energy
        playSerializer
        artistCollab
        preRelease
        availableForClick
        position
        hasStems
        isNew
        rollingRank
      }
      artist {
        id
        name
        slug
        catalog {
          id
          hexColor
        }
      }
    }
  }
`
