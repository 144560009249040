import { useEffect, useState, useRef } from "react"
import SongItemBeta from "../../shared/components/SongItemBeta"
import cx from "classnames"

const SongsContainer = ({ songs, user_role }) => {
  const currentBlanketDealModalRef = useRef(null)
  const thisContainerRef = useRef(null)
  const [expandedSongVersionId, setExpandedSongVersionId] = useState(false)

  const handleSetExpandedSongVersionId = (svid) => {
    setExpandedSongVersionId(svid)
  }

  const handleOnClick = (e) => {
    if (
      // Not clicked in actions menu OR NOT clickd within this song version container
      (!e.target.closest([".actions-menu", ".expand-actions-menu"]) ||
        !thisContainerRef.current.contains(e.target)) &&
      // AND Not clicked within the blanket deal modal currently open
      !(currentBlanketDealModalRef.current && currentBlanketDealModalRef.current.contains(e.target))
    ) {
      setExpandedSongVersionId(false)
    }
  }

  useEffect(() => {
    //For some reason these seem to break the SelectBlanketOrderModals
    document.body.addEventListener("click", handleOnClick)

    return () => {
      document.body.removeEventListener("click", handleOnClick)
    }
  }, [])

  return !songs[0] ? ( //Done loading but there are no songs
    <div ref={thisContainerRef}></div>
  ) : (
    //Done loading, also there are songs
    <div id="mm-song-versions" ref={thisContainerRef}>
      {/* <h5 className="hFive">You May Also Like</h5> */}
      <article id="mm-song-versions-listing" aria-label="Songs listing">
        <div className="mm-song-versions-table" data-title="Search results">
          <ol className={cx("mm-song-versions-table-list")}>
            <li className="mm-song-versions-table-head">
              <ol>
                <li className="song-version">Song / Artist</li>
                <li className="genres desktop">Genres</li>
                <li className="arc desktop">Arc&trade;</li>
                <li className="energy desktop" data-touch>
                  Energy
                </li>
                <li className="length">Length</li>
              </ol>
            </li>
            {songs.map((song) => (
              <SongItemBeta
                key={song.songId}
                song={song}
                user_role={user_role}
                handleSetExpandedSongVersionId={handleSetExpandedSongVersionId}
                expandedSongVersionId={expandedSongVersionId}
                currentBlanketDealModalRef={currentBlanketDealModalRef}
              />
            ))}
          </ol>
        </div>
      </article>
    </div>
  )
}

export default SongsContainer
